<template>
  <div>
    <div
      class="row align-items-center mb-4"
    >
      <div class="col-md-8">
        <b-button
          size="sm"
          variant="margin-reset btn btn-outline-primary btn-sm"
          @click="backToOverview"
        >
          <i class="ion-ios-arrow-back" />
          {{ $t('distributionDetail.button.back') }}
        </b-button>
      </div>
      <cp-head-table
        :title="pageTitle"
        title-icon="ios-git-network"
      />
    </div>
    <div
      v-if="!tableUpdated"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('distributionDetail.loading') }}
      </div>
    </div>
    <cp-table
      ref="cpTable"
      get-data-action="snapshotsRecords/getPayoutsSnapshotRecordsList"
      :url-params="urlParams"
      :fields="innerTableFields"
      class="mt-4"
      :filters="filters"
      @tableDataUpdated="tableDataUpdated"
    >
      <div
        slot="tableHeader"
        class="card-header d-flex justify-content-end"
      >
        <b-button
          size="sm"
          :disabled="isLoadingCsvDownload"
          variant="margin-reset btn btn-outline-primary btn-sm ml-2"
          @click="downloadCsv"
        >
          <i class="ion-ios-download" />
          {{ isLoadingCsvDownload ? $t('distributionDetail.button.downloading') : $t('distributionDetail.button.download') }}
        </b-button>
      </div>
      <template
        slot="name"
        slot-scope="{rowData:{item}}"
      >
        <div>
          {{ item.name }}
        </div>
      </template>
      <template
        slot="email"
        slot-scope="{rowData:{item}}"
      >
        <div>
          {{ item.email }}
        </div>
      </template>
      <template
        slot="tokens"
        slot-scope="{rowData:{item}}"
      >
        <div>
          {{ item.tokens | exponentialToDecimal }}
        </div>
      </template>
      <template
        slot="tax-forms"
        slot-scope="{rowData:{item}}"
      >
        <template
          v-if="item['tax-forms'].length"
        >
          <div
            v-for="(taxForm, index) of item['tax-forms']"
            :key="taxForm.id"
            class="tax-form-link"
          >
            <a
              href="javascript:void(0)"
              @click="() => downloadTaxForm(taxForm.id)"
            >
              {{ `${taxForm.type}${index !== (item['tax-forms'].length - 1) ? ',' : ''}` }}
            </a>
          </div>
        </template>
        <template v-else>
          {{ '-' }}
        </template>
      </template>
      <template
        slot="payout-method"
        slot-scope="{rowData:{item}}"
      >
        <div>
          {{ item['payout-method'] || 'treasury' }}
        </div>
      </template>
    </cp-table>
    <generate-distribution-modal
      ref="generateDistributionModal"
      :distributions-modal-state="distributionsModalState"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { innerTableFields } from '../fields';
import routeNames from '~/utilities/routes';

import generateDistributionModal from '../components/generate-distribution-modal';
import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';

export default {
  name: 'Distributions',

  metaInfo: {
    title: 'Distributions',
  },

  components: {
    CpTable,
    CpHeadTable,
    generateDistributionModal,
  },

  data() {
    return {
      urlParams: {
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
        snapshotId: this.$route.params.idSnapshot,
      },
      snapshotName: this.$route.params.name,
      issuerId: this.$route.params.idIssuer,
      idSnapshot: this.$route.params.idSnapshot,
      innerTableFields,
      tableUpdated: false,
      filters: {},
      distributeCreated: false,
      pageTitle: this.$route.params.name,
      isLoadingCsvDownload: false,
    };
  },

  computed: {
    distributionsModalState() {
      return {
        issuerId: this.issuerId,
        id: +this.idSnapshot,
        snapshotName: this.snapshotName,
      };
    },
  },
  watch: {
    filters: {
      handler() {},
      deep: true,
    },
    $route({ params: { tokenId: newTokenId, idIssuer } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId && newTokenId !== oldTokenId) {
        this.$router.push(routeNames.distributions(idIssuer, newTokenId));
      }
    },
  },

  methods: {
    ...mapActions('snapshotsRecords', ['getDistributionDownloadUrl', 'getTaxFormSignedUrl']),

    toViewPage({ id: idHolder }) {
      // TODO make more pretty on distributions refactoring
      this.$router.push(routeNames.viewDetails(this.issuerId, this.$route.params.tokenId, idHolder));
    },
    downloadCsv() {
      this.isLoadingCsvDownload = true;
      this.getDistributionDownloadUrl({
        issuerId: this.issuerId,
        tokenId: this.$route.params.tokenId,
        snapshotId: this.idSnapshot.toString(),
      }).finally(() => {
          this.isLoadingCsvDownload = false;
      });
    },
    downloadTaxForm(taxFormId) {
      this.getTaxFormSignedUrl({ taxFormId });
    },
    tableDataUpdated() {
      this.tableUpdated = true;
    },
    showModal() {
      this.$refs.generateDistributionModal.$refs.generateDistribution.show();
    },
    backToOverview() {
      const { idIssuer, tokenId } = this.$route.params;
      this.$router.push(routeNames.distributions(idIssuer, tokenId));
    },
    applyFilters(filter) {
      this.filters = { 'has-payout-wallet': filter };
    },
  },
};
</script>

<style scoped>
  .btn-margin-reset {
    margin-top: 0;
  }
  .tax-form-link {
    display: inline;
  }
</style>
