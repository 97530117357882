var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center mb-4"},[_c('div',{staticClass:"col-md-8"},[_c('b-button',{attrs:{"size":"sm","variant":"margin-reset btn btn-outline-primary btn-sm"},on:{"click":_vm.backToOverview}},[_c('i',{staticClass:"ion-ios-arrow-back"}),_vm._v(" "+_vm._s(_vm.$t('distributionDetail.button.back'))+" ")])],1),_c('cp-head-table',{attrs:{"title":_vm.pageTitle,"title-icon":"ios-git-network"}})],1),(!_vm.tableUpdated)?_c('div',{staticClass:"row align-items-center mb-2"},[_c('div',{staticClass:"col-md-12 text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$t('distributionDetail.loading'))+" ")])]):_vm._e(),_c('cp-table',{ref:"cpTable",staticClass:"mt-4",attrs:{"get-data-action":"snapshotsRecords/getPayoutsSnapshotRecordsList","url-params":_vm.urlParams,"fields":_vm.innerTableFields,"filters":_vm.filters},on:{"tableDataUpdated":_vm.tableDataUpdated},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.rowData.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"email",fn:function(ref){
var item = ref.rowData.item;
return [_c('div',[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"tokens",fn:function(ref){
var item = ref.rowData.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("exponentialToDecimal")(item.tokens))+" ")])]}},{key:"tax-forms",fn:function(ref){
var item = ref.rowData.item;
return [(item['tax-forms'].length)?_vm._l((item['tax-forms']),function(taxForm,index){return _c('div',{key:taxForm.id,staticClass:"tax-form-link"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function () { return _vm.downloadTaxForm(taxForm.id); }}},[_vm._v(" "+_vm._s(("" + (taxForm.type) + (index !== (item['tax-forms'].length - 1) ? ',' : '')))+" ")])])}):[_vm._v(" "+_vm._s('-')+" ")]]}},{key:"payout-method",fn:function(ref){
var item = ref.rowData.item;
return [_c('div',[_vm._v(" "+_vm._s(item['payout-method'] || 'treasury')+" ")])]}}])},[_c('div',{staticClass:"card-header d-flex justify-content-end",attrs:{"slot":"tableHeader"},slot:"tableHeader"},[_c('b-button',{attrs:{"size":"sm","disabled":_vm.isLoadingCsvDownload,"variant":"margin-reset btn btn-outline-primary btn-sm ml-2"},on:{"click":_vm.downloadCsv}},[_c('i',{staticClass:"ion-ios-download"}),_vm._v(" "+_vm._s(_vm.isLoadingCsvDownload ? _vm.$t('distributionDetail.button.downloading') : _vm.$t('distributionDetail.button.download'))+" ")])],1)]),_c('generate-distribution-modal',{ref:"generateDistributionModal",attrs:{"distributions-modal-state":_vm.distributionsModalState}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }